.breadcrumb {
    padding: 0;
    background: none;
}

.breadcrumb-item {
    a {
        color: $gray-700 !important;
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Font Awesome 5 Pro";
        }
    }
}

.breadcrumb-item.active {
    color: $gray-600;
}

.logo-sm {
    img {
        height: 0px;
        display: none;
    }
}

.logo-lg {
    img {
        height: 0px;
        display: none;
    }
}

#page-topbar {
    position: relative !important;
}

.navbar-brand-box {
    padding: 0 0 0 0.75rem;
}

body[data-layout=horizontal] {
    .page-content {
        margin-top: 0;
        padding-top: 30px;
    }
    .page-content.no-padding-bottom {
        padding-bottom: 0 !important;
    }
}

.dropdown-megamenu {
    padding-left: 12px;
    padding-right: 12px;
    left: 0px !important;
    right: 0px !important;
    transform: none !important;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
}

.megamenu-list {
    li {
        padding-left: 10px;
        padding-right: 10px;

        a {
            display: block;
        }
    }
}

.topnav {
    margin-top: 0;
    position: relative;
}

.card {
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.header-bar {
    background-color: $white;
}

.btn {
    border: none;
    box-shadow: none !important;

    &:disabled {
        background-color: $gray-500 !important;
        color: $gray-600 !important;

        &:hover {
            background-color: $gray-500 !important;
            color: $gray-600 !important;
        }
    }
}

.form-control:disabled, .form-control[readonly] {
    background-color: $gray-100;
}

.login-body { 
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .container-fluid {
        max-width: 100% !important;
    }

    .login-panel-col {
        min-width: 320px;
        max-width: 360px;
    }
}

label {
    margin-bottom: 0.3rem;
}

.col-form-label {
    padding-bottom: 0;
}

.card-header {
    background-color: $primary;
    color: $primary-text;
    font-weight: $font-weight-light;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.card-footer {
    border-top: 1px solid $light-grey;
    background-color: transparent;
}

.client-color {
    padding: 4px 8px;
    margin-right: 10px;
    min-width: 40px;
}

.vc-chrome {
	position: absolute;
	top: 35px;
	right: 0;
	z-index: 9;
}

.current-color {
	display: inline-block;
	width: 19px;
	height: 19px;
	background-color: #000;
	cursor: pointer;
}

.field-error {
    border: 3px solid $red !important;
}

.tickbox {
    color: $primary;
    font-size: 2rem;
    margin-right: 5px;
    cursor: pointer;
}

.main-nav-container {
    width: 100%;
    box-shadow: 0 1rem 0.75rem rgb(0 0 0 / 2%);

    .status-bar {
        background-color: $gray-200;
        min-height: 27px;
    }

    .status-bar.active {
        .btn-light:hover {
            background-color: rgba($white, 0.1);
        }

        .btn-light:focus {
            background-color: rgba($white, 0.1);
        }
    }
}

.main-nav-container.fixed {
    top: 0px;
    position: fixed;
    z-index: 100;
}

.fa-toggle-on.fa-flip-horizontal {
    cursor: pointer;
    color: $gray-500;
}

.asset-preview {
    .thumbnail {
        background-color: $gray-200;
        border-top-left-radius: $border-radius-sm;
        border-top-right-radius: $border-radius-sm;
        height: 200px;
        padding: 1px;
        position: relative;

        img {
            max-width: calc(100% - 2px);
            max-height: 198px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .card-body {
        padding: 0.75rem;

        .title {
            height: 20px;
            overflow: hidden;
        }

        .asset-info {
            opacity: 1;
            transition: opacity 0.6s ease;
        }

        .asset-menu {
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: 100%;
            padding: 2px 12px;
            height: 37px;
            background-color: transparent;
            transition: background-color 0.6s ease;
            border-bottom-left-radius: $border-radius-sm;
            border-bottom-right-radius: $border-radius-sm;

            a {
                color: $gray-700;
            }

            .asset-tools {
                opacity: 0;
                transition: opacity 0.6s ease;
                position: absolute;
                width: calc(100% - 24px);
            }

            .asset-tools-mobile-toggle {
                display: none;
            }

            .select-asset {
                opacity: 0;
                transition: opacity 0.6s ease;
                position: absolute;
                right: 12px;
                top: 4px;
                z-index: 2;
            }

            .asset-selected {
                transition: color 0.6s ease;
                position: relative;
                top: -1px;
                font-size: 25px;
            }

            .asset-restricted {
                color: $gray-500;
                opacity: 1;
                transition: opacity 0.6s ease;
                position: relative;
                top: -3px;
            }
        }

        .asset-menu.full {
            .asset-tools {
                opacity: 1;
            }

            .select-asset {
                opacity: 1;
            }
        }
    }
}

.asset-preview.selectable:hover, .asset-preview.selected {
    .card-body {
        .asset-menu {
            border-bottom-left-radius: 0.1rem;
            border-bottom-right-radius: 0.1rem;
        }
    }
}

.header-item {
    .btn {
        font-size: 0.85rem;
    }

    .avatar-title {
        background-color: rgba($white, 0.25);
        opacity: 0.8;
    }
}

body[data-topbar=dark] {
    .navbar-header {
        .dropdown.show {
            .header-item {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.topnav {    
    .navbar-nav {
        .nav-link {
            font-size: 18px;
            font-weight: $font-weight-bold;
            line-height: 1.1;
        }

        h6 {
            font-size: 18px;
            position: relative;
            padding: 1rem 1.3rem 1rem 0;
            color: $menu-item-color;
            margin-bottom: 0;
            font-weight: $font-weight-bold;
            line-height: 1.1;
        }
    }
}

/* Dropzone */

.vue-dropzone {
    border: 3px dashed $light-grey;
    border-radius: $border-radius-lg;
    padding: 10px;
    position: relative;

    .dz-preview {
        display: none;
    }

    .dz-overlay {
        position: absolute;
        top: -3px;
        left: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        background-color: rgba(0, 0, 0, 0.05);
        z-index: 2;
        cursor: not-allowed;
    }
}

.dz-drag-highlight {
    background-color: $light-grey;
}

#asset-dropzone {
    min-height: 140px;
}

#asset-add-files {
    padding-top: 50px;
}

.dz-uploaded-file {
    position: relative;
    background-color: $gray-100;
    border: 1px solid $gray-100;
    cursor: pointer;

    &:hover {
        background-color: $light-grey !important;
        border: 1px solid;

        &:after {
            background-color: $light-grey !important;
        }
    }

    .dz-radio {
        display: inline-block;
        width: 20px;
        vertical-align: middle;
    }

    .dz-thumbnail {
        display: inline-block;
        width: 135px;
        height: 78px;
        vertical-align: middle;
        position: relative;
        margin-right: 12px;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-height: 78px;
        }

        .fa-file {
            font-size: 70px;
            color: $white;
            margin-left: 40px;
            margin-top: 3px;
        }

        .view-image-icon {
            position: absolute;
            left: 48px;
            top: 18px;
            padding: 5px 8px;
            line-height: 1.4;
            font-size: 20px;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
            border-radius: 20px;
            display: none;
            cursor: pointer;
        }
    }

    .dz-thumbnail.bulk-files {
        border-radius: $border-radius;
        text-align: center;
        padding-top: 10px;
        font-size: 20px;

        .number {
            font-weight: $font-weight-bolder;
            font-size: 40px;
            line-height: 35px;
        }
    }

    .dz-filename {
        display: inline-block;
        width: calc(100% - 178px);
        max-height: 60px;
        overflow: hidden;
        overflow-wrap: break-word;
        vertical-align: top;
        margin-bottom: 35px;
    }

    .dz-size {
        position: absolute;
        bottom: 22px;
        right: 38px;
    }

    .dz-remove {
        position: absolute;
        bottom: 21px;
        right: 12px;
        font-size: 18px;

        a {
            color: $gray-700;

            &:hover {
                color: rgba($gray-700, 0.8);
            }
        }
    }

    .dz-upload-info {
        position: absolute;
        left: 180px;
        bottom: 22px;
        display: inline-block;

        a {
            color: $gray-700;

            &:hover {
                color: rgba($gray-700, 0.8);
            }
        }
    }

    .dz-progress {
        position: absolute;
        bottom: 12px;
        right: 12px;
        width: calc(100% - 192px);
        height: 3px;
        background-color: $white;

        .dz-upload {
            position: absolute;
            height: 3px;
        }
    }
}

.dz-uploaded-file.active {
    background-color: $white;
    border: 1px solid;

    &:after {
        background-color: $white;
    }
}

.dz-add-files {
    display: block;
    text-align: center;
    margin: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
}

.asset-overall-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $gray-100;
}

.asset-overall-upload {
    position: absolute;
    height: 3px;
}

.contact-links {
    a {
        color: $gray-700;

        &:hover {
            color: rgba($gray-700, 0.8) !important;
        }
    }
}

.list-details-td {
    background-color: $white;
}

.list-details {
    display: none;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
}

td.highlighted {
    background-color: $gray-100;

}

#print-order-modal {
    > div {
        transition: max-width .35s ease;
    }
}

#asset-add-form-modal, #proof-add-form-modal {
    > div {
        transition: max-width .35s ease;
    }

    .modal-body {
        padding-left: 0;
        padding-right: 0;
        overflow-y: hidden;

        > .row {
            height: 100%;

            .upload-column {
                height: 100%;
                max-height: 490px;
                overflow-y: auto;
            }

            .form-column {
                height: 100%;
                max-height: 490px;
                overflow-y: auto;
            }

            .proof-upload-column {
                height: 100%;
                max-height: 490px;
                overflow-y: auto;
            }

            .proof-form-column {
                height: 100%;
                max-height: 490px;
                overflow-y: auto;
            }
        }
    }
}

.date-presets-button {
    border-top: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.dropdown-mega > button, button.header-item.left {
    font-size: 16.25px;
    font-weight: $font-weight-light;
}

h5.modal-title {
    overflow-x: hidden;
    overflow-wrap: break-word;
}

/*span.filter-text .svg-inline--fa {
    position: relative;
    top: 1px;
}*/

.vue-daterange-picker {
    width: 100%;

    .daterangepicker .drp-buttons .btn {
        font-weight: 400;
    }
}

.keywords-separator {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
}

.vue-drag-ghost {
    background-color: $gray-200 !important;
}

.right-bar.scrollable {
    overflow: auto;
    .slimscroll-menu {
        height: auto !important;
    }
}

.nav-pills {
    .nav-item {
        .nav-link {
            font-weight: $font-weight-normal;
            background-color: $light-grey;

            &:hover {
                background-color: $gray-400;
            }
        }
    }
}

.nav-pills.kwa {
    .nav-item {
        .nav-link.active {
            background-color: $gray-700;
        }
    }
}

.nav-pills.ad {
    .nav-item {
        .nav-link {
            background-color: transparent;
            border: 1px solid $gray-500;
            color: $gray-700;
            padding: 0.2rem 0.6rem;
            font-size: 22px;

            &:hover {
                background-color: $gray-200;
            }
        }
    }
}

.badge {
    font-weight: $font-weight-normal;
}

.badge-soft-secondary {
    color: $gray-700;
}

.btn-link {
    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }
}

.asset-selection {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    transition: height 0.35s ease;
    z-index: 2;
    box-shadow: 0 -1rem 0.75rem rgba(0, 0, 0, 0.02);

    .tools {
        height: 60px;
    }

    .btn-link {
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    .view-selection {
        background-color: $gray-400;
    }
}

.dropdown-header {
    font-size: 16.25px;
    font-weight: $font-weight-light;
}

.main-content {
    overflow: visible;
}

.assets-grid.hide {
    display: none;
}

.card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

button.big-icon {
    width: 40px;
    font-size: 18px;
    padding-top: 4px;
    padding-bottom: 4px;
}

#company_contact_name-label {
    p:last-child {
        margin-bottom: 0;
    }
}

.download-option-title {
    background-color: $gray-200;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.download-option-button {
    position: absolute;
    top: -1px;
    right: -1px;
    height: 38px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    padding-top: 4px;
}

.tui-image-editor-help-menu {
    display: none !important;
}

.tui-image-editor-controls {
    display: none !important;
}

.tui-image-editor-header {
    display: none;
}

.tui-image-editor-main-container {
    background-color: $gray-200 !important;
    bottom: 0 !important;
}
.tui-image-editor-main {
    top: 0 !important;
}

.tui-image-editor-wrap {
    overflow: hidden !important;
}

.tui-image-editor-container {
    min-height: 280px !important;
}

.home-panel .card-body strong {
    font-weight: 500 !important;
}

.collaborate-bar {
    position: relative;
    transition: top .05s linear;
    background-color: white; //$gray-200;

    .tab-content {
        /*border-left: 1px solid $gray-400;
        border-right: 1px solid $gray-400;
        border-bottom: 1px solid $gray-400;
        background-color: $white;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;*/
        min-height: 300px;
        max-height: 350px;
        height: auto;
        overflow-y: auto;
        transition: height 0.35s ease;

        .row.collaborator {
            &:hover {
                background-color: $light-grey;
            }
        }

        .row.collaborator.active {
            background-color: $light-grey;
        }
    }
}

#template-add-form {
    .tab-content.forms {
        border-left: 1px solid $gray-400;
        border-right: 1px solid $gray-400;
        border-bottom: 1px solid $gray-400;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

.mandatory-field {
    border-radius: $border-radius;
}

.right-bar-enabled.pinned {
    .page-topnav {
        margin-right: 300px;
    }

    .status-bar {
        margin-right: 300px;
    }

    .main-content {
        margin-right: 300px;
    }

    .rightbar-overlay {
        right: auto;
        bottom: auto;
    }

    .right-bar {
        z-index: 1040;
    }
}

.profile-group-section {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;

    .button {
        position: relative;
        display: inline-block;
        cursor: pointer;
        padding-top: 6px;
        height: 31px;
        border-radius: $border-radius;
        transition: background-color 0.35s ease;

        &:hover {
            background-color: rgba($white, 0.1);
        }
    }

    .counter {
        display: inline-block;
        position: absolute;
        left: 300px;
        margin-top: 6px;
    }
}

.asset-select-overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 60px;
    overflow: hidden;
    border-top-right-radius: $border-radius-sm;
    display: none;

    .triangle {
        position: absolute;
        top: -16px;
        right: -25px;
        width: 100px;
        height: 50px;
        rotate: 36deg;
    }

    .tick {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 43px;
        width: 80px;
        height: 60px;
        font-size: 32px;
        line-height: 1.2;
    }

    .ghost {
        position: absolute;
        top: 24px;
        right: 5px;
        width: 100px;
        height: 50px;
        rotate: 36deg;
    }
}

.asset-select-overlay.big {
    width: 100px;
    height: 75px;
    border-top-right-radius: unset;

    .triangle {
        top: -20px;
        right: -31px;
        width: 125px;
        height: 63px;
    }

    .tick {
        padding-left: 54px;
        width: 100px;
        height: 75px;
        font-size: 40px;
        cursor: pointer;
    }

    .ghost {
        top: 30px;
        right: 6px;
        width: 125px;
        height: 63px;
    }
}

.asset-select-overlay.deselect {
    .tick {
        .check-icon {
            display: inline;
        }

        .minus-icon {
            display: none;
        }

        &:hover {
            .check-icon {
                display: none;
            }
        
            .minus-icon {
                display: inline;
            }
        }
    }
}

.asset-preview:hover, .asset-preview.highlighted {
    .asset-select-overlay {
        border-top-right-radius: 0.1rem;
    }

    .asset-select-overlay.big {
        border-top-right-radius: unset;
    }
}

.asset-preview:hover, .asset-preview.highlighted, .asset-preview.selected {
    .asset-select-overlay {
        display: block;
    }
}

.asset-select-overlay.big.deselect, .asset-select-overlay.big.show {
    display: block;
}

.related-assets-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 60px;
    overflow: hidden;

    .triangle {
        position: absolute;
        top: -16px;
        left: -25px;
        width: 100px;
        height: 50px;
        rotate: -36deg;
    }

    .tick {
        position: absolute;
        top: 0;
        right: 0;
        padding-left: 43px;
        width: 80px;
        height: 60px;
        font-size: 32px;
        line-height: 1.2;
    }

    .ghost {
        position: absolute;
        top: 24px;
        left: 5px;
        width: 100px;
        height: 50px;
        rotate: -36deg;
    }
}

.related-assets-overlay.big {
    width: 100px;
    height: 75px;

    .triangle {
        top: -20px;
        left: -31px;
        width: 125px;
        height: 63px;
    }

    .tick {
        padding-top: 7px;
        padding-left: 10px;
        width: 100px;
        height: 75px;
        font-size: 27px;
        cursor: pointer;
    }

    .ghost {
        top: 30px;
        left: 6px;
        width: 125px;
        height: 63px;
    }
}

/*.tox .tox-collection--list .tox-collection__item--active {
    background-color: $gray-100 !important;
}

.tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
    color: rgb(34, 47, 62) !important;
}*/

.tox .tox-edit-area::before {
    border: none !important;
}

.tox-tinymce-inline {
    z-index: 1600 !important;
}

.monitoring {
    display: none;
}

@media (max-width: 539px) {
    .right-bar {
        overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }
}

@media (max-width: 575px) {
    // xs
    .menu-toggle {
        margin-left: 20px;
    }

    /*.login-body {
        .login-panel-col {
            min-width: auto;
        }
    }*/

    /*.nav-pills.ad {
        .nav-item {
            .nav-link {
                padding: 0.1rem 0.4rem;
                font-size: 18px;
            }
        }
    }*/

    .download-option-button {
        display: block;
        width: 100%;
        position: relative;
        top: 1px;
        left: -1px;
        right: auto;
        width: calc(100% + 2px);
        height: auto;
    }
}

@media (max-width: 767px) {
    // sm
    .asset-selection {
        .tools {
            height: 100px;
        }
    }

    .collaborate-bar {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: -14px;
        z-index: 100;
        box-shadow: 0 -0.1rem 0.75rem rgba(0, 0, 0, 0.2);

        .tab-content {
            min-height: 0px;
            max-height: none;
            height: 0px;

            #c-collaborators {
                opacity: 0;
            }

            #c-comments {
                opacity: 0;
            }
        }
    }
}

@media (max-width: 991px) {
    // md
    #asset-add-form-modal, #proof-add-form-modal {
        .modal-body {
            overflow-y: auto;
    
            > .row {
                height: auto;

                .upload-column, .proof-upload-column {
                    height: auto;
                    max-height: none;
                    overflow-y: visible;
                }

                .form-column, .proof-form-column {
                    height: auto;
                    max-height: none;
                    overflow-y: visible;
                }
            }
        }
    }

    #asset-dropzone {
        min-height: 140px;
    }

    #asset-add-files {
        padding-top: 50px;
    }

    .dropdown-mega > button, button.header-item.left {
        font-size: 13px;
    }

    .asset-preview {
        .card-body {
            .asset-info.hide {
                opacity: 0;
            }

            .asset-menu {
                .asset-tools {
                    display: none;
                }

                .asset-tools-mobile-toggle {
                    display: inline;
                }
            }

            .asset-menu.full {
                .asset-tools {
                    opacity: 1;
                    display: inline;
                }

                .asset-restricted {
                    opacity: 0;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    // lg
    .asset-preview {
        &:hover {
            .card-body {
                .asset-info {
                    opacity: 0;
                }

                .asset-menu {
                    .asset-tools {
                        opacity: 1;
                    }

                    .select-asset {
                        opacity: 1;
                    }

                    .asset-restricted {
                        opacity: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    // lg
    .dropdown-megamenu {
        margin-left: 10px;
        margin-right: 10px;
    }

    .dropdown-megamenu.subfolders {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 1200px) {
    // xl
    .dropdown-megamenu {
        margin-left: 0.7%;
        margin-right: 0.7%;
    }

    .dropdown-megamenu.subfolders {
        margin-left: 0;
        margin-right: 0;
    }
}
